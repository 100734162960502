import {
	LandingProductTourSection,
	LandingProductTourList,
	LandingProductTourTrigger,
	LandingProductTourContent,
} from '#app/components/landing/LandingProductTour'
import Image from '#app/components/shared/Image.js'
import { VideoPlayer } from '#app/components/shared/VideoPlayer.js'
import { cn } from '#app/utils/misc.js'
import { postSummary } from './assets/videos'

export default function FeaturesSection() {
	return (
		<>
			<LandingProductTourSection
				titleComponent={
					<h2 className="text-5xl font-semibold leading-tight">
						All Your Social Media Saves,
						<br />
						Organized.
					</h2>
				}
				// descriptionComponent={
				// 	<div className="flex max-w-xl flex-col">
				// 		<p className="mt-4 md:text-xl">
				// 			Gnomie is an intuitive garden design tool that makes your outdoor
				// 			space look beautiful.
				// 		</p>

				// 		<p className="mt-4 opacity-50 md:text-xl">
				// 			It automatically suggests plants, flowers, and landscaping
				// 			features based on your region's climate and soil conditions.
				// 		</p>
				// 	</div>
				// }
				defaultValue="feature-1"
			>
				<LandingProductTourList title="Super nice">
					<LandingProductTourTrigger value="feature-1">
						<p className="text-xl font-bold">Collect from LinkedIn & Twitter</p>
						<p>Automatically sync your saved posts and bookmarks.</p>
					</LandingProductTourTrigger>

					<LandingProductTourTrigger value="feature-2">
						<p className="text-xl font-bold">Categorize & Tag</p>
						<p>Organize with custom categories and tags for easy access.</p>
					</LandingProductTourTrigger>

					<LandingProductTourTrigger value="feature-3">
						<p className="text-xl font-bold">AI-Powered Automation</p>
						<p>Let AI help categorize, tag, and organize your saved content.</p>
					</LandingProductTourTrigger>
				</LandingProductTourList>
				<LandingProductTourContent value="feature-1">
					<Image
						className={'hard-shadow w-full rounded-md'}
						src={
							'https://shipixen.com/_next/image?url=https%3A%2F%2Fpicsum.photos%2Fid%2F206%2F800%2F600&w=1080&q=75'
						}
					/>
				</LandingProductTourContent>
				<LandingProductTourContent value="feature-2">
					<Image
						className={'hard-shadow w-full rounded-md'}
						src={
							'https://shipixen.com/_next/image?url=https%3A%2F%2Fpicsum.photos%2Fid%2F33%2F800%2F600&w=1080&q=75'
						}
					/>
				</LandingProductTourContent>
				<LandingProductTourContent value="feature-3">
					<VideoPlayer
						className={cn('hard-shadow w-full overflow-hidden rounded-md')}
						src={postSummary}
						autoPlay={true}
						controls={false}
						loop={true}
						muted={true}
						maxWidth={'100%'}
					/>
				</LandingProductTourContent>
			</LandingProductTourSection>
		</>
	)
}
