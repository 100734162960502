import { type MetaFunction } from '@remix-run/node'

export const meta: MetaFunction = () => [{ title: 'Social Saves' }]

import { useOptionalUser } from '#app/utils/user.js'
import Header from './__header'
import HowItWorks from './__how-it-works'
import ImageCtaSection from './__image-cta-section'
import FeaturesSection from './__product-tour'

export default function Component() {
	const user = useOptionalUser()
	return (
		<div className="flex w-full flex-col items-center scroll-smooth">
			<Header className="absolute mb-0 lg:mb-0" loggedIn={!!user} />
			<ImageCtaSection />
			<FeaturesSection />
			<HowItWorks />
		</div>
	)
}
