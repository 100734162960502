import { Link } from '@remix-run/react'
import { FolderHeart } from 'lucide-react'
import { Button } from '#app/components/ui/button.js'
import { cn } from '#app/utils/misc.js'

const Header = ({
	className,
	loggedIn,
}: {
	className?: string
	loggedIn?: boolean
}) => {
	return (
		<header
			className={cn(
				'wide-container mb-20 flex w-full flex-wrap items-center justify-between py-6 lg:mb-32',
				className,
			)}
		>
			<div>
				<Link to="/" aria-label="Social Saves">
					<div className="dark:hidden">
						<div className="flex items-center gap-2 font-display text-xl font-black text-primary">
							<FolderHeart className="h-6 w-6" />
							<span className="">Social Saves</span>
						</div>
					</div>
					<div className="hidden dark:flex">
						<div className="flex items-center gap-2 font-display text-xl font-black text-primary">
							<FolderHeart className="h-6 w-6" />
							<span className="">Social Saves</span>
						</div>
					</div>
				</Link>
			</div>
			<div className="flex items-center gap-2 leading-5 sm:gap-4">
				{loggedIn ? (
					<Button variant={'outlineDestructive'} asChild>
						<Link to="posts">Go to the app</Link>
					</Button>
				) : (
					<>
						<Button variant={'ghost'} asChild>
							<Link to="login">Login</Link>
						</Button>
						<Button variant={'outlineDestructive'} asChild>
							<Link to="signup">Sign up</Link>
						</Button>
					</>
				)}

				{/* <SearchButton /> */}
				{/* <ThemeSwitch /> */}

				{/* {!hideMenuItems ? <MobileNav /> : null} */}
			</div>
		</header>
	)
}

export default Header
