import { Link } from '@remix-run/react'
import { LandingPrimaryTextCtaSection } from '#app/components/landing/cta/LandingPrimaryCta.js'
import { Button } from '#app/components/ui/button.js'
import { colors } from '#app/data/config/colors.js'

export default function ImageCtaSection() {
	return (
		<>
			<LandingPrimaryTextCtaSection
				className="pt-20"
				title="Your bookmarks deserve better"
				description={
					<div className="md:max-w-lg md:text-lg">
						<p>Turn forgotten saved posts into valuable insights.</p>
						<p>
							Categorize, tag, and automate your LinkedIn and Twitter bookmarks
							to see them in a new perspective.
						</p>
					</div>
				}
				textPosition="center"
				withBackground
				variant="secondary"
			>
				<div className="mt-4 flex w-full flex-col justify-center gap-4">
					<Button
						size="xl"
						className="p-7 text-xl font-semibold"
						variant={'outlineDestructive'}
						asChild
					>
						<Link to="/signup">Try Social Saves for free</Link>
					</Button>
					<p className="text-sm opacity-50">No credit card required</p>
				</div>
			</LandingPrimaryTextCtaSection>

			{/* Background gradient */}
			<div
				className="fixed left-0 top-0 -z-10 h-full w-full"
				style={{
					backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(
						` <svg xmlns="http://www.w3.org/2000/svg"><defs><radialGradient id="a" cx="50%" cy="56.6%" r="50%" fx="50%" fy="56.6%" gradientUnits="userSpaceOnUse"><stop offset="0%" style="stop-color:${colors.primary.dark};stop-opacity:0.1"/><stop offset="54.99%" style="stop-color:${colors.primary.darker};stop-opacity:0.1"/><stop offset="100%" style="stop-color:${colors.secondary.darker};stop-opacity:0.1"/></radialGradient></defs><rect width="100%" height="100%" fill="url(#a)"/></svg>`,
					)}')`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundAttachment: 'fixed',
				}}
			></div>
		</>
	)
}
