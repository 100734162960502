import { LandingProductFeature } from '#app/components/landing/LandingProductFeature.js'
import { LandingProductFeatureKeyPoints } from '#app/components/landing/LandingProductFeatureKeyPoints.js'
import { Button } from '#app/components/ui/button.js'

export default function HowItWorks() {
	const keyPoints = [
		{
			title: 'Install the Browser Extension',
			description:
				'Install our simple browser extension to start collecting your saved posts and bookmarks from LinkedIn and Twitter automatically.',
		},
		{
			title: 'Connect Your LinkedIn & Twitter Accounts',
			description:
				'Securely link your LinkedIn and Twitter accounts to sync your saved posts and bookmarks in real time.',
		},
		{
			title: 'View All Your Saved Posts in One Place',
			description:
				'Access your LinkedIn and Twitter saves in one organized feed, ready for you to browse, categorize, or tag',
		},
	]

	return (
		<LandingProductFeature
			title="How it works."
			descriptionComponent={
				<>
					<LandingProductFeatureKeyPoints
						keyPoints={keyPoints}
						variant="primary"
						className="mt-4 space-y-6"
					/>

					<Button
						className="mx-auto my-4 md:mx-0"
						asChild
						size={'xl'}
						variant={'outlineDestructive'}
					>
						<a href="#">Get started</a>
					</Button>
				</>
			}
			imageSrc="https://shipixen.com/_next/image?url=https%3A%2F%2Fpicsum.photos%2Fid%2F71%2F800%2F800&w=1080&q=75"
			imageAlt="Screenshot of the product"
			imagePosition="left"
			imagePerspective="none"
			zoomOnHover={false}
		/>
	)
}
