/* Configure colors at https://shipixen.com/color-theme-explorer-shadcn */
export const colors = {
	primary: {
		lighter: '#5eead4',
		light: '#2dd4bf',
		main: '#14b8a6',
		dark: '#0d9488',
		darker: '#0f766e',
	},
	secondary: {
		lighter: '#fcd34d',
		light: '#fbbf24',
		main: '#f59e0b',
		dark: '#d97706',
		darker: '#b45309',
	},
}
